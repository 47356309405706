import styled from "@emotion/styled";

export const Container = styled.div`
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.14);
  padding: .6rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;

  img {
    height: 40px;
  }
`