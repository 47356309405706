export const fileExtension = (file: File) => {
  const splitFilename = file.name.split('.')
  const extensionFile = `.${splitFilename.pop()}`
  
  return extensionFile
}

export function dataURLtoFile(dataurl: string, filename: string) {
  var arr = dataurl.split(',') as any,
      mime = arr[0]?.match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}

export const downloadFileByKey = (key?: string) => {
  if(!key) return 
  return window.open(`${process.env.VITE_APP_REST_API_URL}files/download/file/${key}`)
}