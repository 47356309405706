import { useTheme } from "@emotion/react"
import { Box, Button, Typography, useMediaQuery } from "@mui/material"
import { FormHeader } from "../FormWallet/components"
import { Link } from "react-router-dom"

export const WalletApproved = () => {
  const theme = useTheme()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box
      sx={{ 
        padding: isLowerSm ? '1.2rem' : '2rem',
        background: 'white',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.14)'
      }}
    > 
      <FormHeader />
      <Box sx={{ display: 'flex', flexFlow: 'column' }}>
        <Typography sx={{ fontSize: '1.5rem', fontWeight: 500, color: theme.palette.grey[800] }}>Sua carteira digital está disponível!</Typography>
        <Typography sx={{ fontSize: '1rem', color: theme.palette.grey[800], padding: '1.8rem 0 1rem 0', lineHeight: '160%', maxWidth: '540px' }}>
          Oba! Você já está apto a alterar seu tipo de benefício para cashback, assim como unificar suas faturas para pagamento único. Acesse sua área do cliente para ativar as duas modalidades 🤩
        </Typography>
        <Typography sx={{ fontSize: '.9rem', color: theme.palette.grey[400], maxWidth: '320px' }}>Siga o passo a passo dos banners da página inicial ou acesse o menu configurações</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '1.6rem'}}>
          <Link style={{ width: '100%', maxWidth: '400px' }} to='/'>
            <Button sx={{ borderRadius: '2rem', width: '100%', maxWidth: '400px' }}>Ir para minha área do cliente</Button>
          </Link>
        </Box>
      </Box>

    </Box>
  )
}