import Slider, { Settings, CustomArrowProps } from 'react-slick'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import { Container } from './styles'
import { useAuth, useContract } from '~/contexts'
import { CashbackOrDiscountBanner, CashbackJoinBanner, DigitalAccountBanner, UnifyEnergyBillSlide } from './components'
import { EDigitalAccountStatus, ERewardType } from '~/graphql/types'
import { theme } from '~/utils'
import { getCashbackBanner, getSingleAccountBanner } from '~/utils/token'
import { useState } from 'react'
import 'slick-carousel/slick/slick.css'

const ChevronColor = (currentSlide: number, digitalAccountStatus: EDigitalAccountStatus) => {
  if(currentSlide === 0) {
    if(digitalAccountStatus === EDigitalAccountStatus.approved) {
      return theme.palette.success.main
    }
    if(digitalAccountStatus === EDigitalAccountStatus.disapproved) {
      return theme.palette.error.main
    }
    if(digitalAccountStatus === EDigitalAccountStatus.inAnalysis) {
      return theme.palette.info.dark
    }

    return '#561885'
  }

  return '#561885'
}

type MyCustomArrowProps = CustomArrowProps & {
  digitalAccountStatus: EDigitalAccountStatus
}


const SampleNextArrow = (props: MyCustomArrowProps) => {
  const { className, onClick, currentSlide, digitalAccountStatus } = props

  return (
    <button
      className={className}
      onClick={onClick}
    >
      <IconChevronRight size={48} color={ChevronColor(currentSlide || 0, digitalAccountStatus)} />
    </button>
  )
}

const SamplePrevArrow = (props: MyCustomArrowProps) => {
  const { className, onClick, currentSlide, digitalAccountStatus } = props
  return (
    <button
      className={className}
      onClick={onClick}
    >
      <IconChevronLeft size={48} color={ChevronColor(currentSlide || 0, digitalAccountStatus)} />
    </button>
  )
}

export const HomeBanners: React.FC = () => {
  const { authUser } = useAuth()
  const { selectedContract } = useContract()
  const [cashbackJoinBannerIsVisible, setCashbackJoinBannerIsVisible] = useState(() => Boolean(getCashbackBanner()))
  const [singleAccountBannerIsVisible, setSingleAccountBannerIsVisible] = useState(() => Boolean(getSingleAccountBanner()))

  const settings: Settings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    nextArrow: <SampleNextArrow digitalAccountStatus={authUser?.digitalAccount?.status as any} />,
    prevArrow: <SamplePrevArrow digitalAccountStatus={authUser?.digitalAccount?.status as any} />,
    verticalSwiping: false,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: 'progressive',
    responsive: [
      {
        breakpoint: 600,
        settings: {
          arrows: false
        }
      }
    ]
  }

  const isCashback = selectedContract?.reward?.type === ERewardType.cashback
  const isSingleAccount = selectedContract?.singleAccount?.active
  
  const showUnifyBanner = authUser?.digitalAccount?.status === EDigitalAccountStatus.approved && !isSingleAccount && !singleAccountBannerIsVisible
  const showDigitalAccountBanner = [!isCashback, !isSingleAccount].some(item => item)
  const showCashbackBanner = 
    authUser?.digitalAccount?.status === EDigitalAccountStatus.approved 
    && selectedContract?.eligibleForCashback 
    && !cashbackJoinBannerIsVisible 
    && !isCashback

  const slideBanners = [<CashbackOrDiscountBanner />]

  // if(showDigitalAccountBanner) {
  //   slideBanners.unshift(<DigitalAccountBanner />)
  // }

  // if(showUnifyBanner) {
  //   slideBanners.push(<UnifyEnergyBillSlide setSingleAccountBannerIsVisible={setSingleAccountBannerIsVisible} />)
  // }
  
  // if(showCashbackBanner) {
  //   slideBanners.push(<CashbackJoinBanner setCashbackJoinBannerIsVisible={setCashbackJoinBannerIsVisible} />)
  // }
  
  return (
    <>
      <Container>
        {slideBanners.length > 1 ? (
          <Slider {...settings}>
            {slideBanners.map(item => item)}
          </Slider>
        ) : (
          <CashbackOrDiscountBanner />
        )}
      </Container>
    </>
  )
}
