import { DigitalAccountPendecyDto, EDigitalAccountDocumentType } from "~/graphql/types";
import { FormData } from "./types";

type assembleFilesToUploadResponse = {
  filesToUpload: File[][]
  typesToUpload: EDigitalAccountDocumentType[]
}

export const assembleFilesToUpload = (data: FormData): assembleFilesToUploadResponse => {
  const filesToUpload: File[][] = []
  const typesToUpload: EDigitalAccountDocumentType[] = []

  if(data?.identificationFront && data?.identificationFront?.length > 0) {
    filesToUpload.push(data?.identificationFront)
    typesToUpload.push(EDigitalAccountDocumentType.identificationFront)
  }

  if(data?.identificationBack && data?.identificationBack?.length > 0) {
    filesToUpload.push(data?.identificationBack)
    typesToUpload.push(EDigitalAccountDocumentType.identificationBack)
  }

  if(data?.selfie && data?.selfie?.length > 0) {
    filesToUpload.push(data?.selfie)
    typesToUpload.push(EDigitalAccountDocumentType.selfie)
  }

  if(data?.addressProof && data?.addressProof?.length > 0) {
    filesToUpload.push(data?.addressProof)
    typesToUpload.push(EDigitalAccountDocumentType.addressProof)
  }

  if(data?.balance_sheet && data?.balance_sheet?.length > 0) {
    filesToUpload.push(data?.balance_sheet)
    typesToUpload.push(EDigitalAccountDocumentType.balance_sheet)
  }

  if(data?.social_contract && data?.social_contract?.length > 0) {
    filesToUpload.push(data?.social_contract)
    typesToUpload.push(EDigitalAccountDocumentType.social_contract)
  }

  if(data?.others && data?.others?.length > 0) {
    filesToUpload.push(data?.others)
    typesToUpload.push(EDigitalAccountDocumentType.others)
  }

  return { filesToUpload, typesToUpload }
}

export const fileIsRequiredByPendency = (pendencies?: DigitalAccountPendecyDto | null): number => {
  if(!pendencies) return 1

  if(pendencies.inAnalysisAt) {
    return 0
  }

  if(pendencies.approvedAt) {
    return 0
  }

  if(pendencies.rejectedAt) {
    return 1
  }

  return 1
}