import { useTheme } from "@emotion/react"
import { Box, Button, Typography, useMediaQuery } from "@mui/material"
import { IconCurrencyDollar } from "@tabler/icons-react"
import { ChangeToCashback } from "~/components/ChangeToCashback"
import { useToggle } from "~/hooks"

export const CashbackBanner: React.FC = () => {
  const { isTrue: cashbackModalIsVisible, toggle: toggleCashbackModal } = useToggle()
  const theme = useTheme()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <Box sx={{ padding: '0 0 2rem 0' }}>
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'column',
            gap: '1rem',
            padding: isLowerSm ? '1.2rem 1.6rem' :  '1.8rem 2rem',
            borderRadius: '12px',
            background: theme.palette.primary.light,
            border: `1px solid ${theme.palette.primary.dark}`
          }}
        >
          <Box sx={{ display: 'flex', alignItems: isLowerSm ? 'flex-start' : 'center', gap: '.4rem', flexFlow: isLowerSm ? 'column' : 'row' }}>
            <IconCurrencyDollar size={24} color={theme.palette.grey[800]} />
            <Typography sx={{ fontWeight: 600, color: theme.palette.grey[800] }}>CASHBACK TODO MÊS!</Typography>
          </Box>
          <Typography sx={{ fontSize: isLowerSm ? '1rem' : '1.15rem', color: theme.palette.grey[800] }}>
            Ative o cashback para receber parte da sua fatura de volta diretamente na sua carteira 🤑
          </Typography>
          <Button type="button" onClick={toggleCashbackModal} sx={{ width: 'fit-content' }} color="purple">ATIVAR CASHBACK</Button>
        </Box>
      </Box>
      <ChangeToCashback isVisible={cashbackModalIsVisible} toggle={toggleCashbackModal} />
    </>
  )
}