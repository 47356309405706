import styled from "@emotion/styled";

type ContainerProps = {
  $filterOpacity?: boolean
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-image: url('/images/bg-customer-wallet.png');
  background-size: cover;
  padding: 2rem;
  filter: ${(props) => props.$filterOpacity ? 'opacity(35%)' : ''};
`

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: 480px;

  h1 {
    display: block;
    background: ${(props) => props.theme.palette.purple.main};
    text-transform: uppercase;
    font-size: 1.25rem;
    color: ${(props) => props.theme.palette.common.white};
    width: fit-content;
    padding: .2rem .4rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    margin: 0;
  }

  h5 {
    color: ${(props) => props.theme.palette.common.white};
    font-size: 1.35rem;
    font-weight: 600;
    margin: 0;
    padding: 1.4rem 0;
  }

  p {
    color: ${(props) => props.theme.palette.common.white};
    margin: 0;
    line-height: 150%;
  }
`