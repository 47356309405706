import styled from '@emotion/styled'
import { EDigitalAccountStatus } from '~/graphql/types';
import { colorCustomerStatus, colorDigitalAccountStatus } from '~/utils'

type ContainerProps = {
  status: EDigitalAccountStatus;
}
export const Container = styled.div<ContainerProps>`
  span {
    ${(props) => colorDigitalAccountStatus(props.status)}
    padding: .2rem .6rem;
    border-radius: .4rem;
    font-weight: 600;
    font-size: .8rem;
  }
`
