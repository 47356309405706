import { Box } from "@mui/material"
import { transparentize } from "polished"
import { SelfieInformationsByStatusProps, SelfieOptions } from "../../types"
import { IconEye } from "@tabler/icons-react"
import { downloadFileByKey } from "~/utils/file"

type DownloadButtonProps = {
  selectedSelfie: SelfieInformationsByStatusProps
  selfieOptions: SelfieOptions
}

export const DownloadButton: React.FC<DownloadButtonProps> = ({ selectedSelfie, selfieOptions }) => {

  return (
  <Box
    onClick={() => downloadFileByKey(selfieOptions.fileKey)}
    sx={{
      position: 'absolute', 
      top: '14px', 
      right: '20px', 
      width: '40px', 
      height: '40px', 
      borderRadius: '4px',
      cursor: 'pointer',
      background: 'transparent',
      transition: '.2s ease-in-out',
      ":hover": {
        background: transparentize('0.8', selectedSelfie.color.main)
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <IconEye size={30} color={selectedSelfie.color.main} />
  </Box>
  )
}