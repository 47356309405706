import { FormProvider, useForm } from "react-hook-form"
import { Address, Bank, BasicData, Files, LegalRepresentativy } from "./components"
import { FormData } from "./types"
import { Box, Button, CircularProgress } from "@mui/material"
import { useIuguWallet } from "~/contexts"
import { useCallback, useEffect } from "react"
import * as yup from 'yup'
import { addressWithCountrySchema, bankSchema, documentSchema, phoneSchema } from "~/utils/yupSchema"
import { uploadMultiToIuguWallet } from "~/services/uploadFile"
import { clearString } from "~/utils"
import { assembleFilesToUpload, fileIsRequiredByPendency } from "./utils"
import { useParams } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"

export const Form: React.FC = () => {
  const { hash } = useParams()
  const { isPf, customer, updateDigitalAccount, updateDigitalAccountIsLoading } = useIuguWallet()

  const legalRepresentativeYup = yup.object().shape({
    name: yup.string().required(),
    document: documentSchema.required()
  })

  const schema = yup.object().shape({
    identificationFront: yup.array().min(fileIsRequiredByPendency(customer?.pendecies.identificationFront), 'O documento (frente) é obrigatório').required(),
    identificationBack: yup.array().min(fileIsRequiredByPendency(customer?.pendecies.identificationBack), 'O documento (verso) é obrigatório').required(),
    addressProof: yup.array().min(fileIsRequiredByPendency(customer?.pendecies.addressProof), 'O comprovante de endereço é obrigatório').required(),
    selfie: yup.array().min(fileIsRequiredByPendency(customer?.pendecies.selfie), 'A selfie é obrigatória').required(),
    address: addressWithCountrySchema, 
    bank: bankSchema.required(),
    phone: phoneSchema,
    ...isPf ? {} : {
      customerLegalRepresentative: legalRepresentativeYup,
      balance_sheet: yup.array().min(fileIsRequiredByPendency(customer?.pendecies.balance_sheet), 'O relatório financeiro é obrigatório').required(),
      social_contract: yup.array().min(fileIsRequiredByPendency(customer?.pendecies.social_contract), 'O contrato social é obrigatório').required()
    }
  })

  const formMethods = useForm<FormData>({
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    if(customer) {
      formMethods.setValue('phone', customer.phone)
      if(customer.address) {
        formMethods.setValue('address', {
          zipcode: customer.address.zipcode,
          country: customer.address.country,
          state: customer.address.state,
          city: customer.address.city,
          neighborhood: customer.address.neighborhood,
          street: customer.address.street,
          number: customer.address.number,
          complement: customer.address.complement,
        })
      }
      if(customer.bank) {
        formMethods.setValue('bank', {
          account: customer.bank.account,
          agency: customer.bank.agency,
          code: customer.bank.code,
          type: customer.bank.type,
        })
      }
    }
  }, [])

  const onSubmit = useCallback(async (data: FormData) => {
    const { filesToUpload, typesToUpload } = assembleFilesToUpload(data)

    if(filesToUpload.length > 0) {
      const responseS3 = await uploadMultiToIuguWallet(filesToUpload, typesToUpload)

      updateDigitalAccount({
        variables: {
          hash: hash || '',
          params: {
            address: {
              city: data.address.city,
              country: data.address.country,
              neighborhood: data.address.neighborhood,
              number: data.address.number,
              state: data.address.state,
              street: data.address.street,
              zipcode: clearString(data.address.zipcode),
              complement: data.address.complement,
            },
            ...isPf ? {} : {
              customerLegalRepresentative:
              {
                document: clearString(data.customerLegalRepresentative?.document || ''),
                name: data.customerLegalRepresentative?.name || ''
              }
            },
            bank: {
              account: data.bank.account,
              agency: data.bank.agency,
              code: data.bank.code,
              type: data.bank.type,
            },
            phone: clearString(data.phone),
            documents: responseS3
          }
        }
      })
    } else {
      updateDigitalAccount({
        variables: {
          hash: hash || '',
          params: {
            address: {
              city: data.address.city,
              country: data.address.country,
              neighborhood: data.address.neighborhood,
              number: data.address.number,
              state: data.address.state,
              street: data.address.street,
              zipcode: clearString(data.address.zipcode),
              complement: data.address.complement,
            },
            ...isPf ? {} : {
              customerLegalRepresentative:
              {
                document: clearString(data.customerLegalRepresentative?.document || ''),
                name: data.customerLegalRepresentative?.name || ''
              }
            },
            bank: {
              account: data.bank.account,
              agency: data.bank.agency,
              code: data.bank.code,
              type: data.bank.type,
            },
            phone: clearString(data.phone),
            documents: []
          }
        }
      })
    }

  }, [isPf, hash])

  return (
    <div>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit((data) => onSubmit(data))}>
          <BasicData />
          <Address />
          <Bank />
          {!isPf && (
            <LegalRepresentativy />
          )}
          <Files />
          <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '2rem' }}>
            <Button 
              type="submit" 
              sx={{ borderRadius: '2rem', width: '100%', maxWidth: '500px' }}
              disabled={updateDigitalAccountIsLoading || !formMethods.formState.isValid}
              endIcon={updateDigitalAccountIsLoading ? <CircularProgress size={24} color='inherit' /> : null}
            >
              Criar carteira digital
            </Button>
          </Box>
        </form>
      </FormProvider>
    </div>
  )
}