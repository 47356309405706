import * as yup from 'yup'
import { clearString } from './string'
import { isPhoneValid } from './phoneValidator'
import { isValid as isCpfValid } from '@fnando/cpf'
import { isValid as isCnpjValid } from '@fnando/cnpj'
import { ECustomerPersonType, EMaritalStatus } from '~/graphql/types'
import { fileExtension } from './file'
import { banks } from '~/constants'

export const documentSchema = yup.string().test('Validate CPF/CNPJ', 'Documento deve ser um valor válido', (value: any) => {
  const formattedValue = clearString(value)

  if (formattedValue.length === 11) {
    return isCpfValid(formattedValue)
  } else {
    return isCnpjValid(formattedValue)
  }
})

export const phoneSchema = yup.string().required('Celular é obrigatório')
  .test('Phone length', 'Celular deve ter 10 ou 11 dígitos', phone => phone ? clearString(phone).length === 11 || clearString(phone).length === 10 : false)
  .test(
    'isPhoneValid',
    'Telefone inválido',
    phone => {
      return phone ? isPhoneValid(clearString(phone)) : false
    }
  )

export const maritalStatusSchema = yup.string()
  .equals(Object.values(EMaritalStatus), 'Estado civil inválido')
  .required('Estado civil é obrigatório')

export const personTypeSchema = yup.string()
  .equals(Object.values(ECustomerPersonType), 'Tipo de pessoa inválido')
  .required('Tipo de pessoa é obrigatório')

export const addressSchema = yup.object().shape({
  state: yup.string().optional(),
  complement: yup.string().nullable(),
  city: yup.string().required('Cidade é um campo obrigatório'),
  number: yup.string().required('Número é um campo obrigatório'),
  neighborhood: yup.string().required('Bairro é um campo obrigatório'),
  street: yup.string().min(5, 'Rua deve ter pelo menos 5 caracteres').required('Nome da rua é um campo obrigatório'),
  zipcode: yup.string().test('Length', 'CEP válido necessita ter 8 digitos', (value: any) => clearString(value).length === 8 ? true : false).required('CEP é obrigatório')
})

export const addressWithCountrySchema = yup.object().shape({
  state: yup.string().required(),
  complement: yup.string().nullable(),
  country: yup.string().required('País é um campo obrigatório'),
  city: yup.string().required('Cidade é um campo obrigatório'),
  number: yup.string().required('Número é um campo obrigatório'),
  neighborhood: yup.string().required('Bairro é um campo obrigatório'),
  street: yup.string().min(5, 'Rua deve ter pelo menos 5 caracteres').required('Nome da rua é um campo obrigatório'),
  zipcode: yup.string().test('Length', 'CEP válido necessita ter 8 digitos', (value: any) => clearString(value).length === 8 ? true : false).required('CEP é obrigatório')
})

export const requiredFileSchema = yup.mixed()
  .test('Min files', 'Você precisa enviar pelo menos um arquivo.', (value: any) => value?.length > 0)
  .test('Max files', 'Este campo aceita no máximo 1 arquivo.', (value: any) => value?.length <= 1)
  .test('Size files', 'Este campo aceita arquivo com no máximo 15MB.', (value) => {
    const isValid: boolean = value ? Array.from(value as File[]).every((file) => file?.size < 15728640) : true
    return isValid
  })
  .test('Extension files', 'Os arquivos devem ser no formato .png ou .pdf', (value: any) => {
    const isValid: boolean = value ? Array.from(value).every((file) => ['.png', '.pdf', '.jpg', '.jpeg'].includes(fileExtension(file as File))) : true
    return isValid
  })

export const fileSchema = yup.mixed().nullable().when({
  is: (exists: any) => !!exists && exists.length > 0,
  then: (rule) => rule.test('Min files', 'Você precisa enviar pelo menos um arquivo.', (value: any) => value && value.length > 0 ? true : false)
    .test('Max files', 'Este campo aceita no máximo 1 arquivo.', (value: any) => value && value.length <= 1 ? true : false)
    .test('Size files', 'Este campo aceita arquivo com no máximo 15MB.', (value) => {
      const isValid: boolean = value ? Array.from(value as File[]).every((file) => file?.size < 15728640) : true
      return isValid
    })
    .test('Extension files', 'Os arquivos devem ser no formato .png ou .pdf', (value: any) => {
      const isValid: boolean = value ? Array.from(value).every((file) => ['.png', '.pdf', '.jpg', '.jpeg'].includes(fileExtension(file as File))) : true
      return isValid
    })
})

export const bankAccountSchema = yup.string().required().test('isValid', (value, validation) => {
  const code = validation?.parent?.code
  if(value && Boolean(code)) {
    const selectedBank = banks[code]
    if(selectedBank) {
      const bankLength = selectedBank.accountDigit ? selectedBank.accountLength + 1 : selectedBank.accountLength
      if(bankLength !== clearString(value).length) {
        return validation.createError({
          message: `Este campo deve ter ${bankLength} dígitos.`,
          path: 'account'
        })
      }
      return true
    }
    return true
  }
  return true
})

export const bankAgencySchema = yup.string().required().test('isValid', (value, validation) => {
  const code = validation?.parent?.code
    if(value && Boolean(code)) {
      const selectedBank = banks[code]
      if(selectedBank) {
        const bankLength = selectedBank.agencyDigit ? selectedBank.agencyLength + 1 : selectedBank.agencyLength
        if(bankLength !== clearString(value).length) {
          return validation.createError({
            message: `Este campo deve ter ${bankLength} dígitos.`,
            path: 'agency'
          })
        }
        return true
      }
      return true
    }
    return true
})

export const bankSchema = yup.object().shape({
  code: yup.string().required(),
  type: yup.string().required(),
  account: yup.string().required().test('isValid', (value, validation) => {
    const code = validation?.parent?.code
    if(value && Boolean(code)) {
      const selectedBank = banks[code]
      if(selectedBank) {
        const bankLength = selectedBank.accountDigit ? selectedBank.accountLength + 1 : selectedBank.accountLength
        if(bankLength !== clearString(value).length) {
          return validation.createError({
            message: `Este campo deve ter ${bankLength} dígitos.`,
            path: 'bank.account'
          })
        }
        return true
      }
      return true
    }
    return true
  }),
  agency: yup.string().required().test('isValid', (value, validation) => {
    const code = validation?.parent?.code
    if(value && Boolean(code)) {
      const selectedBank = banks[code]
      if(selectedBank) {
        const bankLength = selectedBank.agencyDigit ? selectedBank.agencyLength + 1 : selectedBank.agencyLength
        if(bankLength !== clearString(value).length) {
          return validation.createError({
            message: `Este campo deve ter ${bankLength} dígitos.`,
            path: 'bank.agency'
          })
        }
        return true
      }
      return true
    }
    return true
  }),
}).default(null)
