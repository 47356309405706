import { useTheme } from "@emotion/react"
import { CircularProgress, Grid, IconButton, Typography } from "@mui/material"
import { IconSearch } from "@tabler/icons-react"
import { useCallback, useEffect } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { Input, Select } from "~/components"
import { brazilStates } from "~/constants"
import { useIuguWallet } from "~/contexts"
import { useCep } from "~/hooks"
import { FormData } from "../../types"

export const Address: React.FC = () => {
  const theme = useTheme()
  const { customer } = useIuguWallet()

  const formMethods = useFormContext<FormData>()

  const { address } = useWatch({ control: formMethods.control })
  const { isLoading: fetchCepIsLoading, fetchCep } = useCep({ showErrorMessage: true })

  const onSearchCep = useCallback(async () => {
    if (address?.zipcode) {
      const { data, isSuccess } = await fetchCep(address.zipcode)
      if (isSuccess) {
        formMethods.setValue('address.state', data?.uf || '')
        formMethods.setValue('address.city', data?.localidade || '')
        formMethods.setValue('address.neighborhood', data?.bairro || '')
        formMethods.setValue('address.street', data?.logradouro || '')
        formMethods.setValue('address.country', 'Brasil')
      }
    }
  }, [address])

    useEffect(() => {
      if(customer) {
        if(customer.pendecies.address.rejectedAt) {
          formMethods.setError('address.city', { message: 'Endereço não encontrado. Verifique e tente novamente.' })
          formMethods.setError('address.complement', { message: 'Endereço não encontrado. Verifique e tente novamente.' })
          formMethods.setError('address.country', { message: 'Endereço não encontrado. Verifique e tente novamente.' })
          formMethods.setError('address.neighborhood', { message: 'Endereço não encontrado. Verifique e tente novamente.' })
          formMethods.setError('address.number', { message: 'Endereço não encontrado. Verifique e tente novamente.' })
          formMethods.setError('address.state', { message: 'Endereço não encontrado. Verifique e tente novamente.' })
          formMethods.setError('address.street', { message: 'Endereço não encontrado. Verifique e tente novamente.' })
          formMethods.setError('address.zipcode', { message: 'Endereço não encontrado. Verifique e tente novamente.' })
        }
      }
    }, [customer])

  return (
    <div>
      <Typography sx={{ fontSize: '1.5rem', fontWeight: 500, color: theme.palette.grey[800], padding: '2rem 0 1rem 0' }}>Seus dados básicos</Typography>

      <Grid spacing={3} container>
        <Grid md={6} xs={12} item>
          <Input
            label='CEP' 
            name='address.zipcode'
            mask="cep"
            icons={{
              end: {
                element: fetchCepIsLoading ?
                  <CircularProgress size={18} color='inherit' /> :
                  <IconButton onClick={() => onSearchCep()}><IconSearch /></IconButton>
              }
            }}
          />
        </Grid>
        <Grid md={6} xs={12} item>
          <Input label='País' name='address.country' />
        </Grid>
        <Grid md={6} xs={12} item>
          <Select options={brazilStates} label='UF' name='address.state' />
        </Grid>
        <Grid md={6} xs={12} item>
          <Input label='Cidade' name='address.city' />
        </Grid>
        <Grid md={6} xs={12} item>
          <Input label='Bairro' name='address.neighborhood' />
        </Grid>
        <Grid md={6} xs={12} item>
          <Input label='Rua' name='address.street' />
        </Grid>
        <Grid md={6} xs={12} item>
          <Input label='Número' name='address.number' />
        </Grid>
        <Grid md={6} xs={12} item>
          <Input label='Complemento (opcional)' name='address.complement' />
        </Grid>
      </Grid>
    </div>
  )
}