import { useTheme } from "@emotion/react"
import { Box, Typography, useMediaQuery } from "@mui/material"

export const WalletError = () => {
  const theme = useTheme()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box
      sx={{ 
        padding: isLowerSm ? '1.2rem' : '2rem',
        background: 'white',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.14)'
      }}
    > 
      <Box sx={{ display: 'flex', flexFlow: 'column' }}>
        <Typography sx={{ fontSize: '1.5rem', fontWeight: 500, color: theme.palette.grey[800] }}>Ops!</Typography>

        <Box sx={{ display: 'flex', flexFlow: 'column', gap: '.2rem', padding: '1.6rem 0 1rem 0' }}>
          <Typography sx={{ fontSize: '1rem', color: theme.palette.grey[800] }}>Página inválida.</Typography>
          <Typography sx={{ fontSize: '1rem', color: theme.palette.grey[800] }}>Verifique o link de acesso e tente novamente.</Typography>
        </Box>

        <Typography sx={{ fontSize: '.9rem', color: theme.palette.grey[400] }}>Você pode encontrá-lo diretamente no banner da página inicial da sua área do cliente.</Typography>
      </Box>

    </Box>
  )
}