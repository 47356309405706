import styled from "@emotion/styled";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background: rgba(0,0,0, 0.9);
`

export const ButtonScreenshot = styled.div`
  position: fixed;
  background: white;
  bottom: 0;
  left: 0;
  right: 0;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem auto;
  border-radius: 50%;
  cursor: pointer;

  &::after {
    content: '';
    width: 60px;
    height: 60px;
    background: transparent;
    border-radius: 50%;
    border: 2px solid #000;
  }
`