import React from "react";
import DespertaLogo from '~/assets/images/logo/desperta-logo-horizontal.svg'
import { Container } from "./styles";

export const SimpleHeader: React.FC = () => {
  return (
    <Container>
      <img src={DespertaLogo} />
    </Container>
  )
}