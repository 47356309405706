import { useTheme } from "@emotion/react"
import { Typography, useMediaQuery } from "@mui/material"
import { IconRefresh } from "@tabler/icons-react"

export const PickNewSelfie: React.FC = () => {
  const theme = useTheme()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Typography sx={{ 
      fontSize: isLowerSm ? '.9rem' : '1rem',
      display: 'flex',
      gap: '.4rem',
      alignItems: 'center',
      color: theme.palette.error.main
    }}>
      <span>Tirar novamente</span> <IconRefresh size={16} />
    </Typography>
  )
}