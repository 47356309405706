import { CSSProperties } from "react"

export enum ESelfieStatus {
  default = "default",
  error = "error",
  success = "success",
  inAnalysys = "inAnalysis",
  dropped = "dropped",
}

export type SelfieOptions = {
  fileUrl?: string
  fileKey?: string
  label?: string
}

export type SelfieInformationsByStatusProps = {
  disabled: boolean
  cursor: CSSProperties['cursor']
  showPickNewSelfie: boolean
  statusInformations?: {
    isVisible: boolean
    text: string
    color: CSSProperties['color']
  }
  color: {
    main: string
    background: string
    label: string
    description: string
  }
}