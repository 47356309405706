import { useTheme } from "@emotion/react"
import { Box, Grid, Typography } from "@mui/material"
import { Input } from "~/components"

export const LegalRepresentativy: React.FC = () => {
  const theme = useTheme()

  return (
    <Box sx={{ padding: '1rem 0' }}>
      <Typography sx={{ fontSize: '1.5rem', fontWeight: 500, color: theme.palette.grey[800], padding: '1.2rem 0 1rem 0' }}>Dados de representante legal</Typography>

      <Grid spacing={3} container>
        <Grid md={6} xs={12} item>
          <Input label='Nome' name='customerLegalRepresentative.name' />
        </Grid>
        <Grid md={6} xs={12} item>
          <Input mask='cpf' label='CPF' name='customerLegalRepresentative.document' />
        </Grid>
      </Grid>
    </Box>
  )
}