import React, { InputHTMLAttributes, useCallback, useRef } from "react";
import Webcam from "react-webcam";
import { ButtonScreenshot, Container } from "./styles";
import { dataURLtoFile } from "~/utils/file";
import { Controller, useFormContext } from "react-hook-form";

type ScreenshotProps = InputHTMLAttributes<HTMLInputElement> & {
  isVisible: boolean
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export const Screenshot: React.FC<ScreenshotProps> = ({ isVisible, setIsVisible, ...rest }) => {
  const { setValue, clearErrors } = useFormContext()

  const videoConstraints = {
    aspectRatio: 4/3
  };

  const webcamRef = useRef<any>(null);

  const capture = useCallback(
    () => {
      const imageSrc = webcamRef?.current?.getScreenshot()
      const imageFile = dataURLtoFile(imageSrc, 'selfie.jpeg')
      setValue('selfie', [imageFile], { shouldValidate: true })
      setIsVisible(false)
      clearErrors('selfie')
    },
    [webcamRef]
  );

  return (
    <>
      <Container>
        <Webcam
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          ref={webcamRef}
          forceScreenshotSourceSize={true}
          mirrored={true}
          audio={false} 
          style={{
            width: "100%",
            height: '100%'
          }}
          />
        <ButtonScreenshot onClick={capture} />
      </Container>
    </>
  )
}