import { useTheme } from "@emotion/react"
import { Box, Typography, useMediaQuery } from "@mui/material"
import { FormHeader } from "../FormWallet/components"

export const WalletInAnalysis = () => {
  const theme = useTheme()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box
      sx={{ 
        padding: isLowerSm ? '1.2rem' : '2rem',
        background: 'white',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.14)'
      }}
    >
      <FormHeader />
      <Box sx={{ display: 'flex', flexFlow: 'column' }}>
        <Typography sx={{ fontSize: '1.5rem', fontWeight: 500, color: theme.palette.grey[800] }}>Suas informações estão sendo analisadas</Typography>
        <Typography sx={{ fontSize: '1rem', color: theme.palette.grey[800], padding: '1.8rem 0 1rem 0', lineHeight: '160%', maxWidth: '560px' }}>
          Seus dados e documentos estão em análise. Estamos trabalhando para que você possa aproveitar o cashback e a fatura única o mais breve possível! Agora é só aguardar nosso retorno 😉
        </Typography>
        <Typography sx={{ fontSize: '.9rem', color: theme.palette.grey[400] }}>Fique ligado! Enviaremos atualizações sobre sua carteira via e-mail.</Typography>
      </Box>

    </Box>
  )
}