/* eslint-disable quote-props */
import { DigitalAccountDocumentFileUpdateDto, EDigitalAccountDocumentType, EProposalFileType, EStorageService, FileModel, FileUploadModel, ProposalModel } from '~/graphql/types'
import { restApi } from './api'
import { clearString } from '~/utils'
import { getToken } from '~/utils/token'

const headers = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*',
  'Authorization': `Bearer ${getToken()}` || ''
}

type UpdateFilesInProposalInput = {
  type: EProposalFileType,
  file: FileUploadModel,
  proposalId: string
  legalRepresentativeDocument?: string
}
const updateFilesInProposal = async (params: UpdateFilesInProposalInput): Promise<ProposalModel> => {
  const { proposalId, ...rest } = params
  const { data: updatedProposal }: { data: ProposalModel } = await restApi.put(`proposals/${proposalId}/update/files`, { ...rest }, { headers: { Authorization: `Bearer ${getToken()}` || '' } })

  return updatedProposal
}

export const uploadToS3 = async (file: File[]): Promise<FileModel> => {
  const fileFormData = new FormData()

  file.forEach(mappedFile => {
    fileFormData.append('file', mappedFile)
  })

  const { data: sentFile }: { data: FileModel } = await restApi.post('files/upload', fileFormData, { headers })
  return sentFile
}

export type UploadFileParams = {
  file: File[]
  proposalId: string
  type: EProposalFileType
  legalRepresentativeDocument?: string
}
export const uploadFile = async (params: UploadFileParams): Promise<ProposalModel> => {
  const { file, proposalId, legalRepresentativeDocument, type } = params

  const sentFile = await uploadToS3(file)

  const formattedData: UpdateFilesInProposalInput = {
    type,
    file: {
      key: sentFile.key,
      fileUrl: sentFile.fileUrl,
      fileName: sentFile.fileName,
      storageService: sentFile.storageService || EStorageService.awsS3
    },
    proposalId
  }

  const formattedDataForLegalRepresentative: UpdateFilesInProposalInput =
    legalRepresentativeDocument
      ? {
        ...formattedData,
        legalRepresentativeDocument: clearString(legalRepresentativeDocument)
      }
      : formattedData

  const updatedProposal = await updateFilesInProposal(formattedDataForLegalRepresentative)

  return updatedProposal
}

export type UploadMultiFilesParams = {
  files: File[][],
  proposalId: string
  types: EProposalFileType[],
  legalRepresentativeDocument?: string
}
export const uploadMultiFiles = async (params: UploadMultiFilesParams): Promise<ProposalModel> => {
  const { files, types, proposalId, legalRepresentativeDocument } = params
  const updatedProposals: ProposalModel[] = []

  const fileFormData = new FormData()

  files.forEach(mappedFile => {
    fileFormData.append('files', mappedFile[0])
  })

  const { data: sentFiles }: { data: FileModel[] } = await restApi.post('files/upload/multi', fileFormData, { headers })

  for (let i = 0; i < sentFiles.length; i++) {
    const formattedData: UpdateFilesInProposalInput = {
      type: types[i],
      proposalId,
      file: {
        key: sentFiles[i].key,
        fileUrl: sentFiles[i].fileUrl,
        fileName: sentFiles[i].fileName,
        storageService: sentFiles[i].storageService || 'awsS3'
      }
    }

    const formattedDataForLegalRepresentative: UpdateFilesInProposalInput =
      legalRepresentativeDocument
        ? {
          ...formattedData,
          legalRepresentativeDocument: clearString(legalRepresentativeDocument)
        }
        : formattedData

    const updatedProposal = await updateFilesInProposal(formattedDataForLegalRepresentative)
    updatedProposals.push(updatedProposal)
  }

  return updatedProposals[updatedProposals.length - 1]
}

// MULTIPLOS ARQUIVOS


export const uploadMultiToS3 = async (files: File[][]): Promise<FileModel[]> => {
  const fileFormData = new FormData()

  files.forEach(mappedFile => {
    fileFormData.append('files', mappedFile[0])
  })

  const { data: sentFiles }: { data: FileModel[] } = await restApi.post('files/upload/multi', fileFormData, { headers })

  return sentFiles
}

type uploadMultiToIuguWalletResponseProps = {
  file: FileModel
  type: EDigitalAccountDocumentType
}


export const uploadMultiToIuguWallet = async (files: File[][], types: EDigitalAccountDocumentType[]): Promise<DigitalAccountDocumentFileUpdateDto[]> => {
  
  const fileFormData = new FormData()

  files.forEach(mappedFile => {
    fileFormData.append('files', mappedFile[0])
  })

  const { data: sentFiles }: { data: FileModel[] } = await restApi.post('files/upload/multi', fileFormData, { headers })

  const response: uploadMultiToIuguWalletResponseProps[] = sentFiles.map((_a,i) => {
    return {
      file: sentFiles[i],
      type: types[i] as EDigitalAccountDocumentType
    }
  })

  const formatedResponse: DigitalAccountDocumentFileUpdateDto[] = response.map(item => {
    return {
      fileName: item.file.fileName,
      fileUrl: item.file.fileUrl,
      key: item.file.key,
      mimeType: item.file.mimetype,
      storageService: EStorageService.awsS3,
      type: item.type
    }
  })

  return formatedResponse
}
