import React from "react";
import { Container } from "./styles";
import { translateDigitalAccountStatus } from "~/utils";
import { EDigitalAccountStatus } from "~/graphql/types";

type DigitalAccountStatusProps = {
  status: EDigitalAccountStatus
}
export const DigitalAccountStatus: React.FC<DigitalAccountStatusProps> = ({ status }) => {
  return (
    <Container status={status || 'pending'}>
      <span>{translateDigitalAccountStatus(status || 'peding')}</span>
    </Container>
  )
}