import React from "react";
import { Container, Content } from "./styles";
import { useIuguWallet } from "~/contexts";
import { EDigitalAccountStatus } from "~/graphql/types";

export const WalletBanner: React.FC = () => {
  const { updateDigitalAccountIsLoading, customer } = useIuguWallet()

  const isOpacity =
    updateDigitalAccountIsLoading ||
    customer?.digitalAccountStatus === EDigitalAccountStatus.approved ||
    customer?.digitalAccountStatus === EDigitalAccountStatus.inAnalysis ||
    Boolean(!customer)

  return (
    <Container $filterOpacity={isOpacity}>
      <Content>
        <h1>abra já sua carteira!</h1>
        <h5>Receba cashback e unifique suas contas!</h5>
        <p>Mais praticidade pra você: cashback direto na sua área do cliente e fatura de energia da Desperta + concessionária disponível para pagamento único.</p>
      </Content>
    </Container>
  )
}