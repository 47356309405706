import { Box, useMediaQuery } from "@mui/material"
import { Form, FormHeader } from "./components"
import { useTheme } from "@emotion/react"

export const FormWallet: React.FC = () => {
  const theme = useTheme()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box 
      sx={{
        padding: isLowerSm ? '1.2rem' : '2rem',
        background: 'white',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.14)'
      }}>
      <FormHeader />
      <Form />
    </Box>
  )
}