import { Dialog } from '../Dialog'
import { Divider, Typography, Button, Box, useMediaQuery, CircularProgress, Grid } from '@mui/material'
import { theme } from '~/utils'
import { ContainerWithScroll, Ol } from './styles'
import { IconInfoCircleFilled } from '@tabler/icons-react'
import {  ERewardType, useUserContractChangeBenefitMutation } from '~/graphql/types'
import { useContract } from '~/contexts'
import { toast } from 'react-toastify'

type ChangeToCashbackProps = {
  isVisible: boolean
  toggle: Function
}

export const ChangeToCashback: React.FC<ChangeToCashbackProps> = ({ isVisible, toggle }) => {

  const { selectedContract, setSelectedContract, setContracts } = useContract()

  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const [changeToCashback, { loading: contractChangeLoading }] = useUserContractChangeBenefitMutation({
    variables: {
      params: {
        contractId: selectedContract?._id || '',
        rewardType: ERewardType.cashback
      }
    },
    onCompleted(data) {
      if(selectedContract?._id) {
        setSelectedContract({ ...selectedContract, reward: data.userContractChangeBenefit.reward || selectedContract?.reward as any })
        setContracts(prev => prev.map(item => item._id === data.userContractChangeBenefit._id ? { ...item, reward: data.userContractChangeBenefit.reward || selectedContract?.reward as any } : item ))
      }
      toast.success('Sua recompensa foi alterada para cashback!')
      toggle()
    },
    onError(error) {
      toast.error(error.message)
    }
  })

  return (
    <Dialog 
      titleIcon={<IconInfoCircleFilled style={{ color: theme.palette.info.dark }} size={32} />} 
      title='Mudar benefício para cashback'
      onClose={() => toggle()} 
      isVisible={isVisible}
    >
      <Divider sx={{ margin: '0 0 1.6rem 0' }}/>
      <Typography sx={{ color: theme.palette.grey['600'], paddingBottom: '1.6rem' }}>Antes de confirmar, esteja ciente:</Typography>

      <ContainerWithScroll>
        <Ol>
          <li>Alterando seu benefício para cashback, sua próxima fatura deixará de vir com percentual de desconto aplicado e você começará a receber faturas com valor integral;</li>
          <li>A partir de agora, a cada fatura paga, você receberá parte do valor total da sua fatura em forma de cashback, isto é, como crédito diretamente na sua carteira digital;</li>
          <li>Agora você pode acompanhar o saldo de cashback acumulado mês a mês ao pagar suas faturas, ter acesso ao extrato detalhado e usar o saldo disponível para realizar transações diretamente na sua carteira.</li>
        </Ol>
      </ContainerWithScroll>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', paddingTop: '2rem', flexFlow: isLowerSm ? 'column' : 'row' }}>
        <Button onClick={() => toggle()} color='secondary'>Voltar</Button>
        <Button 
          endIcon={contractChangeLoading ? <CircularProgress size={24} color='inherit' /> : <></>} 
          disabled={contractChangeLoading} 
          variant='contained' 
          color='info' 
          onClick={() => changeToCashback()} 
          type='submit'
        >
            Quero cashback
        </Button>
      </Box>
    </Dialog>
  )
}
