import { useTheme } from "@emotion/react";
import { Box, Divider, Typography } from "@mui/material";
import { useCallback } from "react";
import { Banner, DigitalAccountStatus } from "~/components";
import { useIuguWallet } from "~/contexts";
import { EDigitalAccountStatus } from "~/graphql/types";

export const FormHeader: React.FC = () => {
  const { customer } = useIuguWallet()

  const renderHeaderByStatus = useCallback(() => {
    if(customer?.digitalAccountStatus === EDigitalAccountStatus.approved) {
      return (
        <Box sx={{ display: 'flex', gap: '.25rem' }}>
          <Typography sx={{ fontWeight: 500, fontSize: '1rem', color: theme.palette.grey[800] }}>Status da carteira digital:</Typography>
          <DigitalAccountStatus status={EDigitalAccountStatus.approved} />
        </Box>
      )
    }

    if(customer?.digitalAccountStatus === EDigitalAccountStatus.disapproved) {
      return (
        <Box>
          <Box sx={{ display: 'flex', gap: '.25rem' }}>
            <Typography sx={{ fontWeight: 500, fontSize: '1rem', color: theme.palette.grey[800] }}>Status da carteira digital:</Typography>
            <DigitalAccountStatus status={EDigitalAccountStatus.disapproved} />
          </Box>
          <Divider sx={{ margin: '1rem 0' }} />
          <Box sx={{ display: 'flex', flexFlow: 'column', gap: '.25rem' }}>
            <Typography sx={{ fontWeight: 500, fontSize: '1.25rem', color: theme.palette.grey[800] }}>Ajuste os campos a seguir</Typography>
            <Typography sx={{ fontSize: '.9rem', color: theme.palette.grey[400], maxWidth: '500px' }}>
              Os campos sinalizados abaixo foram reprovados na criação da sua carteira. Preencha-os novamente para retomar o processo.
            </Typography>
          </Box>
          {customer?.feedback && <Box sx={{ paddingTop: '1rem' }}><Banner padding={{ default: '1rem', sm: '.6rem' }} type="error" text={customer.feedback} /></Box>}
        </Box>
      )
    }

    if(customer?.digitalAccountStatus === EDigitalAccountStatus.inAnalysis) {
      return (
        <Box sx={{ display: 'flex', gap: '.25rem' }}>
          <Typography sx={{ fontWeight: 500, fontSize: '1rem', color: theme.palette.grey[800] }}>Status da carteira digital:</Typography>
          <DigitalAccountStatus status={EDigitalAccountStatus.inAnalysis} />
        </Box>
      )
    }

    return (
      <Box sx={{ display: 'flex', flexFlow: 'column', gap: '.25rem' }}>
        <Typography sx={{ fontWeight: 500, fontSize: '1.2rem', color: theme.palette.grey[800] }}>Preencha os campos a seguir</Typography>
        <Typography sx={{ fontSize: '.9rem', color: theme.palette.grey[400] }}>Comece a desfrutar das vantagens do cashback e fatura única criando sua carteira digital.</Typography>
      </Box>
    )
  }, [customer])

  const theme = useTheme()

  return (
    <div>
      {renderHeaderByStatus()}
      <Divider sx={{margin: '2rem 0'}} />
    </div>
  )
}