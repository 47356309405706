import { Box, Typography } from '@mui/material'
import { PropsWithWizard } from '~/components'
import { UnifyEnergyBillLayout } from '../UnifyEnergyBillLayout'
import { IconArrowRight } from '@tabler/icons-react'

export type UnifyEnergyBillInstructionsProps = {
  setIsVisible: (visible: boolean) => void
}

export const UnifyEnergyBillInstructions: React.FC<PropsWithWizard<UnifyEnergyBillInstructionsProps>> = (props) => {
  return (
    <UnifyEnergyBillLayout
      illustration='ManAndWomanClappingHands'
      backButton={{ onClick: () => props.setIsVisible(false), title: 'Cancelar' }}
      nextButton={{ onClick: () => props.onNext(), title: 'Continuar', icon: <IconArrowRight /> }}
      {...props}
    >
      <Box sx={{
        gap: 4,
        display: 'flex',
        flexDirection: 'column'
      }}>

        <Typography sx={{ lineHeight: '170%' }}>
          Você está aderindo à <b>fatura única.</b> A partir de agora, a fatura da sua concessionária e a fatura da Desperta energia serão <b>unificadas</b> para <b>pagamento único</b>, todos os meses, até quando você desejar..
        </Typography>

        <Typography>Deseja continuar?</Typography>
      </Box>
    </UnifyEnergyBillLayout>
  )
}
