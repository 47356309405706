import { useTheme } from "@emotion/react"
import { Box, Typography } from "@mui/material"
import { IconInfoCircle, IconQuestionMark } from "@tabler/icons-react"
import { Link } from "react-router-dom"
import { DragAndDrop, Selfie } from "~/components"
import { getSelfieStatusByIuguWallet } from "~/components/Selfie/utils"
import { useIuguWallet } from "~/contexts"
import { DigitalAccountPendecyDto, EDigitalAccountDocumentType, EDigitalAccountStatus } from "~/graphql/types"

export const Files: React.FC = () => {
  const theme = useTheme()
  const { isPf, customer } = useIuguWallet()

  const getDragAndDropStatus = (pendency: DigitalAccountPendecyDto) => {
    if(pendency?.approvedAt) {
      return EDigitalAccountStatus.approved
    }
    if(pendency?.rejectedAt) {
      return EDigitalAccountStatus.disapproved
    }

    return EDigitalAccountStatus.inAnalysis
  }

  const identificationFrontFile = customer?.documents.find(item => item.type === EDigitalAccountDocumentType.identificationFront)
  const identificationBackFile = customer?.documents.find(item => item.type === EDigitalAccountDocumentType.identificationBack)
  const addressProofFile = customer?.documents.find(item => item.type === EDigitalAccountDocumentType.addressProof)
  const balanceSheetFile = customer?.documents.find(item => item.type === EDigitalAccountDocumentType.balance_sheet)
  const selfieFile = customer?.documents.find(item => item.type === EDigitalAccountDocumentType.selfie)
  const socialContractFile = customer?.documents.find(item => item.type === EDigitalAccountDocumentType.social_contract)

  return (
    <Box>
      <Typography sx={{ fontSize: '1.5rem', fontWeight: 500, color: theme.palette.grey[800], padding: '1.2rem 0 1rem 0' }}>Documentos</Typography>

      <Box sx={{ display: 'flex', flexFlow: 'column', gap: '2rem', paddingTop: '1.2rem' }}>
        {isPf ? (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '.4rem', marginBottom: '-1rem' }}>
              <IconInfoCircle color={theme.palette.primary.main} />
              <Typography sx={{ color: theme.palette.grey[400], fontSize: '.9rem', lineHeight: '150%' }}>O documento de identificação deve conter seu CPF!</Typography>
            </Box>
            
            <DragAndDrop
              $variant='secondary'
              label='Anexar RG ou CNH (frente)'
              fileDescription='(JPG, JPEG, PNG.)'
              name='identificationFront'
              status={getDragAndDropStatus(customer?.pendecies.identificationFront as DigitalAccountPendecyDto)}
              sentAt={customer?.pendecies.identificationFront.inAnalysisAt || customer?.pendecies.identificationFront.approvedAt}
              $completed={Boolean(customer?.pendecies.identificationFront.inAnalysisAt || customer?.pendecies.identificationFront.approvedAt)}
              fileName={identificationFrontFile?.file.fileName}
              fileKey={identificationFrontFile?.file.key}
              accepted={{
                images: true,
                pdf: false
              }}
            />
          
            <DragAndDrop
              $variant='secondary'
              label='Anexar RG ou CNH (verso)'
              fileDescription='(JPG, JPEG, PNG.)'
              name='identificationBack'
              status={getDragAndDropStatus(customer?.pendecies.identificationBack as DigitalAccountPendecyDto)}
              sentAt={customer?.pendecies.identificationBack.inAnalysisAt || customer?.pendecies.identificationBack.approvedAt}
              $completed={Boolean(customer?.pendecies.identificationBack.inAnalysisAt || customer?.pendecies.identificationBack.approvedAt)}
              fileName={identificationBackFile?.file.fileName}
              fileKey={identificationBackFile?.file.key}
              accepted={{
                images: true,
                pdf: false
              }}
            />

            <DragAndDrop
              $variant='secondary'
              label='Anexar comprovante de residência'
              fileDescription='(JPG, JPEG, PNG.)'
              name='addressProof'
              status={getDragAndDropStatus(customer?.pendecies.addressProof as DigitalAccountPendecyDto)}
              sentAt={customer?.pendecies.addressProof.inAnalysisAt || customer?.pendecies.addressProof.approvedAt}
              $completed={Boolean(customer?.pendecies.addressProof.inAnalysisAt || customer?.pendecies.addressProof.approvedAt)}
              fileName={addressProofFile?.file.fileName}
              fileKey={addressProofFile?.file.key}
              accepted={{
                images: true,
                pdf: true
              }}
            />
          </>
        ) : (
          <>
            <DragAndDrop
              $variant='secondary'
              label='Anexar contrato social'
              fileDescription='(JPG, JPEG, PNG.)' 
              name='social_contract'
              status={getDragAndDropStatus(customer?.pendecies.social_contract as DigitalAccountPendecyDto)}
              sentAt={customer?.pendecies?.social_contract?.inAnalysisAt || customer?.pendecies?.social_contract?.approvedAt}
              $completed={Boolean(customer?.pendecies?.social_contract?.inAnalysisAt || customer?.pendecies?.social_contract?.approvedAt)}
              fileName={socialContractFile?.file.fileName}
              fileKey={socialContractFile?.file.key}
              accepted={{
                images: true,
                pdf: false
              }}
            />

            <DragAndDrop
              $variant='secondary'
              label='Anexar comprovante de endereço'
              fileDescription='(JPG, JPEG, PNG.)' 
              name='addressProof'
              status={getDragAndDropStatus(customer?.pendecies.addressProof as DigitalAccountPendecyDto)}
              sentAt={customer?.pendecies?.addressProof?.inAnalysisAt || customer?.pendecies?.addressProof?.approvedAt}
              $completed={Boolean(customer?.pendecies?.addressProof?.inAnalysisAt || customer?.pendecies?.addressProof?.approvedAt)}
              fileName={addressProofFile?.file.fileName}
              fileKey={addressProofFile?.file.key}
              accepted={{
                images: true,
                pdf: true
              }}
            />

            <Link style={{ width: 'fit-content' }} target="__blank" to='https://despertaenergia.com/arquivos/o_que_e_um_relatorio_financeiro.pdf'>
              <Typography
                sx={{
                  color: theme.palette.info.dark,
                  display: 'flex',
                  alignItems: 'center',
                  gap: '.2rem',
                  textDecoration: 'underline',
                  marginBottom: '-1rem'
                }}>
                <IconQuestionMark />O que é um relatório financeiro?
              </Typography>
            </Link>

            <DragAndDrop
              $variant='secondary'
              label='Anexar relatório financeiro'
              fileDescription='(JPG, JPEG, PNG, PDF.)' 
              name='balance_sheet'
              status={getDragAndDropStatus(customer?.pendecies.balance_sheet as DigitalAccountPendecyDto)}
              sentAt={customer?.pendecies?.balance_sheet?.inAnalysisAt || customer?.pendecies?.balance_sheet?.approvedAt}
              $completed={Boolean(customer?.pendecies?.balance_sheet?.inAnalysisAt || customer?.pendecies?.balance_sheet?.approvedAt)}
              fileName={balanceSheetFile?.file.fileName}
              fileKey={balanceSheetFile?.file.key}
              accepted={{
                images: true,
                pdf: true
              }}
            />

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '.4rem', marginBottom: '-1rem' }}>
              <IconInfoCircle color={theme.palette.primary.main} />
              <Typography sx={{ color: theme.palette.grey[400], fontSize: '.9rem', lineHeight: '150%' }}>O documento de identificação deve conter o CPF do representante legal!</Typography>
            </Box>

            <DragAndDrop
              $variant='secondary'
              label='Anexar RG, CNH ou Passaporte do representante legal (frente)'
              fileDescription='(JPG, JPEG, PNG.)' 
              name='identificationFront'
              status={getDragAndDropStatus(customer?.pendecies.identificationFront as DigitalAccountPendecyDto)}
              sentAt={customer?.pendecies.identificationFront.inAnalysisAt || customer?.pendecies.identificationFront.approvedAt}
              $completed={Boolean(customer?.pendecies.identificationFront.inAnalysisAt || customer?.pendecies.identificationFront.approvedAt)}
              fileName={identificationFrontFile?.file.fileName}
              fileKey={identificationFrontFile?.file.key}
              accepted={{
                images: true,
                pdf: false
              }}
            />

            <DragAndDrop
              $variant='secondary'
              label='Anexar RG, CNH ou Passaporte do representante legal (verso)'
              fileDescription='(JPG, JPEG, PNG.)' 
              name='identificationBack'
              status={getDragAndDropStatus(customer?.pendecies.identificationBack as DigitalAccountPendecyDto)}
              sentAt={customer?.pendecies.identificationBack.inAnalysisAt || customer?.pendecies.identificationBack.approvedAt}
              $completed={Boolean(customer?.pendecies.identificationBack.inAnalysisAt || customer?.pendecies.identificationBack.approvedAt)}
              fileName={identificationBackFile?.file.fileName}
              fileKey={identificationBackFile?.file.key}
              accepted={{
                images: true,
                pdf: false
              }}
            />
          </>
        )}
        
        <Selfie selfieOptions={{ fileUrl: selfieFile?.file.fileUrl, fileKey: selfieFile?.file.key }} status={getSelfieStatusByIuguWallet(customer?.pendecies.selfie)} />

      </Box>

    </Box>
  )
}