import styled from "@emotion/styled";

export const Background = styled.div`
  height: 100%;
  min-height: 100vh;
  background: linear-gradient(1deg, #fafaf8, #faf9f4);
`

export const Container = styled.div`
  padding: 4rem 1rem;
  display: flex;
  justify-content: center;
`

export const Content = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-flow: column;
`