import { useTheme } from "@emotion/react"
import { Grid, Typography } from "@mui/material"
import { useEffect } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { Input, Radio, Select } from "~/components"
import { useIuguWallet } from "~/contexts"
import { useBank } from "~/hooks"
import { bankAccountTypeOptions } from "~/utils/options"
import { FormData } from "../../types"

export const Bank: React.FC = () => {
  const theme = useTheme()
  
  const { customer } = useIuguWallet()
  const formMethods = useFormContext<FormData>()

  const { bank } = useWatch({ control: formMethods.control })

  const { accountLabel, agencyLabel, bankOptions, agencyMask, accountMask } = useBank(bank?.code)

  useEffect(() => {
    if(customer) {
      if(customer.pendecies.account.rejectedAt) {
        formMethods.setError('bank.account', { message: 'Conta informada não existe. Verifique e tente novamente.' })
      }
      if(customer.pendecies.agency.rejectedAt) {
        formMethods.setError('bank.agency', { message: 'Agência informada não existe. Verifique e tente novamente.' })
      }
    }
  }, [customer])

  return (
    <div>
      <Typography sx={{ fontSize: '1.5rem', fontWeight: 500, color: theme.palette.grey[800], padding: '2rem 0 1rem 0' }}>Seus dados básicos</Typography>
        <Grid spacing={3} container>
          <Grid item xs={12} md={6}>
            <Select label='Banco' name='bank.code' options={bankOptions} />
          </Grid>
          <Grid item xs={0} md={6} sx={{ padding: '0 !important' }} />
          <Grid item xs={12} md={6}>
            <Input name='bank.agency' label={agencyLabel} customMask={agencyMask} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input name='bank.account' label={accountLabel} customMask={accountMask} />
          </Grid>
          <Grid item xs={12}>
            <Radio row name='bank.type' options={bankAccountTypeOptions} />
          </Grid>
        </Grid>
    </div>
  )
}