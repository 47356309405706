import { createContext, useContext, useState } from 'react'
import { CustomerData, IuguWalletContextData, IuguWalletProviderProps } from './types'
import { EPersonType, useGetCustomerInfoByHashQuery, useUpdateCustomerDigitalAccountByHashMutation } from '~/graphql/types'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const IuguWalletContext = createContext({} as IuguWalletContextData)

export const IuguWalletProvider: React.FC<IuguWalletProviderProps> = ({ children }) => {
  const [customerIsLoading, setCustomerIsLoading] = useState(true)
  const [customer, setCustomer] = useState<CustomerData | null>(null)
  const { hash } = useParams()

  const isPf = customer?.personType === EPersonType.pf

  const [updateDigitalAccount, { loading: updateDigitalAccountIsLoading }] = useUpdateCustomerDigitalAccountByHashMutation({
    onError(error) {
      toast.error(error.message)
    },
    onCompleted(data) {
      setCustomer(data.updateCustomerDigitalAccountByHash as CustomerData)
      toast.success('Seus dados foram enviados!')
    },
  })
  

  useGetCustomerInfoByHashQuery({
    variables: {
      hash: hash || ''
    },
    onCompleted(data) {
      setCustomer(data.getCustomerInfoByHash)
      setCustomerIsLoading(false)
    },
    onError(error) {
      toast.error('Não foi possível carregar seus dados.')
      setCustomer(null)
      setCustomerIsLoading(false)
    },
  })
 
  return (
    <IuguWalletContext.Provider value={{
      customer,
      setCustomer,
      customerIsLoading,
      updateDigitalAccountIsLoading,
      isPf,
      updateDigitalAccount
    }}>
      {children}
    </IuguWalletContext.Provider>
  )
}

export const useIuguWallet = () => useContext(IuguWalletContext)
