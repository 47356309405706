import { Box, Typography, useMediaQuery } from "@mui/material";
import { IconCamera } from "@tabler/icons-react";
import { DownloadButton, PickNewSelfie, Screenshot } from "./components";
import { useToggle } from "~/hooks";
import { useTheme } from "@emotion/react";
import { Controller, useFormContext } from "react-hook-form";
import { InputHTMLAttributes } from "react";
import { ErrorText } from "../Form/ErrorText";
import { InputHidden } from "./styles";
import { ESelfieStatus, SelfieOptions } from "./types";
import { selfieInformationsByStatus } from "./utils";

type SelfieProps = InputHTMLAttributes<HTMLInputElement> & {
  status?: ESelfieStatus
  selfieOptions?: SelfieOptions
}

export const Selfie: React.FC<SelfieProps> = ({ 
  selfieOptions = { label: 'Tirar foto' },
  status = ESelfieStatus.default,
  ...rest
}) => {
  const theme = useTheme()
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const { control, getValues } = useFormContext()
  const { isTrue: isVisible, setIsTrue: setIsVisible } = useToggle(false)

  const arrayFiles = getValues('selfie')
  
  const SelfieStatus = arrayFiles?.length > 0 ? ESelfieStatus.dropped : status

  const selectedSelfie = selfieInformationsByStatus(SelfieStatus)

  return (
    <>
      <Controller
          defaultValue={[]}
          name='selfie'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <div style={{ position: 'relative' }}>
              <InputHidden
                multiple
                {...field}
                value={field.value && field.value.filename}
                onChange={(event) => field.onChange(event.target.files)}
                disabled={selectedSelfie.disabled}
                {...rest}
              />
               <div>
                <Box>
                  <Box onClick={() => !selectedSelfie.disabled &&  setIsVisible(!isVisible)} sx={{ 
                    border: `2px dashed ${selectedSelfie.color.main}`,
                    background: selectedSelfie.color.background,
                    borderRadius: '8px',
                    display: 'flex',
                    flexFlow: isLowerMd ? 'column' : 'row',
                    alignItems: isLowerSm ? 'flex-start' : 'center',
                    padding: isLowerSm ? '1rem 1.4rem' : '1.2rem 2rem',
                    gap: '.6rem',
                    cursor: selectedSelfie.cursor,
                  }}>
                    <Box sx={{
                      display: 'flex',
                      flexFlow: 'column',
                      alignItems: 'flex-start',
                    }}>
                      <Box sx={{ display: 'flex', gap: '.6rem' }}>
                        <IconCamera size={28} color={selectedSelfie.color.main} />
                        <Typography sx={{ fontSize: isLowerSm ? '1rem' : '1.1rem', fontWeight: 500, color: theme.palette.grey[400] }}>{selfieOptions?.label}</Typography>
                        <Typography sx={{ fontSize: isLowerSm ? '.9rem' : '1rem', color: theme.palette.grey[400] }}>Faça a captura da sua foto</Typography>
                        {selectedSelfie.statusInformations?.isVisible && 
                          <Typography sx={{ fontSize: isLowerSm ? '.9rem' : '1rem', color: selectedSelfie.statusInformations.color }}>{selectedSelfie.statusInformations.text}</Typography>
                        }
                        
                        {arrayFiles?.length > 0 && selectedSelfie.showPickNewSelfie && <PickNewSelfie />}
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {isVisible && (
                  <Screenshot isVisible={isVisible} setIsVisible={setIsVisible} />
                )}
                <Box sx={{ paddingTop: '.4rem' }}>
                  <ErrorText error={error?.message} />
                </Box>
              </div>
              {selfieOptions.fileUrl && (<DownloadButton selfieOptions={selfieOptions} selectedSelfie={selectedSelfie} />)}
            </div>
          )}
        />
    </>
  )
}