import { PageLoader, SimpleHeader } from "~/components"
import { Background, Container, Content } from "./styles"
import { useIuguWallet } from "~/contexts"
import {  FormWallet, WalletInAnalysis, WalletApproved, WalletError, WalletBanner } from "./components"
import { useCallback } from "react"
import { EDigitalAccountStatus } from "~/graphql/types"

export const CreateIuguWallet: React.FC = () => {
  const { customerIsLoading, customer } = useIuguWallet()

  const renderStatusWallet = useCallback(() => {
    if(Boolean(!customer)) {
      return <WalletError />
    }
    if(customer?.digitalAccountStatus === EDigitalAccountStatus.approved) {
      return <WalletApproved />
    }
    if(customer?.digitalAccountStatus === EDigitalAccountStatus.inAnalysis) {
      return <WalletInAnalysis />
    }
   
    return <FormWallet />
  }, [customer])

  if(customerIsLoading) return <PageLoader />

  return (
    <Background>
      <SimpleHeader />
      <Container>
        <Content>
          <WalletBanner />
          {renderStatusWallet()}
        </Content>
      </Container>
    </Background> 
  )
}