import { toast } from 'react-toastify'
import { useMemo, useState } from 'react'
import { IconArrowLeft, IconCheck } from '@tabler/icons-react'
import { Box, CircularProgress, Typography } from '@mui/material'
import { PropsWithWizard } from '~/components'
import { useAuth, useContract } from '~/contexts'
import { UnifyEnergyBillLayout } from '../UnifyEnergyBillLayout'
import { UnifiedEnergyBillSuccess } from '../UnifiedEnergyBillSuccess'
import { ContractChangeSingleAccountDto, ESingleAccountType, useUserContractChangeSingleAccountMutation } from '~/graphql/types'

export type UnifyEnergyBillFeedbackProps = {
  isVisible: boolean
  setIsVisible: (visible: boolean) => void
}

export const UnifyEnergyBillFeedback: React.FC<PropsWithWizard<UnifyEnergyBillFeedbackProps>> = (props) => {
  const { authUser } = useAuth()
  const [requestSuccess, setRequestSuccess] = useState(false)
  const { selectedContract, setSelectedContract, contracts, setContracts } = useContract()
  const [unifyEnergyBillAdhesion, { loading }] = useUserContractChangeSingleAccountMutation()
  const hasBankInfo = Boolean(authUser?.bank?.account && authUser?.bank?.agency && authUser?.bank?.code && authUser?.bank?.type)

  const contractUnifiedEnergyBill = async () => {
    if (selectedContract) {
      try {
        const params: ContractChangeSingleAccountDto = {
          contractId: selectedContract._id,
          type: ESingleAccountType.adhesion
        } 

        const { data } = await unifyEnergyBillAdhesion({ variables: { params } })

        if (data?.userContractChangeSingleAccount.singleAccount) {
          setSelectedContract({
            ...selectedContract,
            singleAccount: data.userContractChangeSingleAccount.singleAccount,
          })

          setContracts(contracts.map(contract => contract._id === selectedContract._id ? {...contract, singleAccount: data.userContractChangeSingleAccount.singleAccount} : {...contract}))
          
        }

        
        setRequestSuccess(true)
      } catch (err) {
        setRequestSuccess(false)
        toast.error('Houve um problema ao contratar a Conta única, tente novamente mais tarde')
      } 
    }
  }

  const onSubmit = async () => {
    if (!hasBankInfo) {
      return props.onNext()
    }

    await contractUnifiedEnergyBill()
  
  }

  const nextButton = useMemo(() => {
    return props.additionalParams.isAutomaticDebitActive
      ? { onClick: () => props.setIsVisible(false), title: 'Entendi', icon: <IconCheck /> }
      : { onClick: async () => await onSubmit(), title: 'Concluir', icon: <IconCheck /> }
  }, [props.additionalParams.isAutomaticDebitActive])

  if (requestSuccess) {
    return <UnifiedEnergyBillSuccess setIsVisible={props.setIsVisible} />
    
  }

  if (loading) {
    return (
      <Box sx={{
        display: 'flex',
        minHeight: 300,
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <CircularProgress size={64} color='inherit' />
      </Box>
    )
  }

  return (
    <UnifyEnergyBillLayout
      loading={loading}
      nextButton={nextButton}
      shouldShowProgress={!props.additionalParams.isAutomaticDebitActive}
      illustration={props.additionalParams.isAutomaticDebitActive ? 'ManHoldingWrench' : 'WomanHoldingGiantPencil'}
      backButton={{ onClick: () => props.onPrev(), title: 'Voltar', icon: <IconArrowLeft /> }}
      {...props}
    >
      <Box sx={{
        gap: 4,
        display: 'flex',
        flexDirection: 'column'
      }}>
        {props.additionalParams.isAutomaticDebitActive ? (
          <Box sx={{ gap: 3, display: 'flex', flexDirection: 'column' }}>
            <Typography>
              Oops! Para evitar o pagamento em duplicidade da fatura, <b>desative</b> o <b>débito automático</b> em sua conta antes de aderir à conta única.
            </Typography>
            <Typography>
              <b>Não se preocupe</b>, após desativar o débito automático, você poderá concluir sua adesão normalmente.
            </Typography>
          </Box>
        ) : (
          <Box sx={{ gap: 3, display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ lineHeight: '160%' }}>
              <b>IMPORTANTE:</b> caso sua fatura de energia da concessionária esteja em <b>débito automático</b>, desative esta opção. Caso contrário, haverá pagamento em <b>duplicidade</b> do valor.
            </Typography>
            <Typography>
              Concluir adesão de conta única?
            </Typography>
          </Box>
        )}
      </Box>
    </UnifyEnergyBillLayout>
  )
}
