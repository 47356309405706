import { useTheme } from "@emotion/react"
import { Box, Grid, Typography } from "@mui/material"
import { Input } from "~/components"
import { useIuguWallet } from "~/contexts"
import { displayCpfCnpj } from "~/utils"

export const BasicData: React.FC = () => {
  const { customer } = useIuguWallet()
  const theme = useTheme()

  return (
    <div>
      <Typography sx={{ fontSize: '1.5rem', fontWeight: 500, color: theme.palette.grey[800] }}>Seus dados básicos</Typography>
      <Box sx={{ display: 'flex', flexFlow: 'column', gap: '.3rem', padding: '1.2rem 0 .6rem 0' }}>
        <Typography sx={{ fontSize: '.9rem', color: theme.palette.grey[400] }}>Nome completo</Typography>
        <Typography sx={{ fontSize: '1rem', color: theme.palette.grey[800] }}>{customer?.name || '-'}</Typography>
      </Box>

      <Box sx={{ display: 'flex', flexFlow: 'column', gap: '.3rem' }}>
        <Typography sx={{ fontSize: '.9rem', color: theme.palette.grey[400] }}>CPF</Typography>
        <Typography sx={{ fontSize: '1rem', color: theme.palette.grey[800] }}>{customer?.document ? displayCpfCnpj(customer.document) : '-'}</Typography>
      </Box>

      <Typography sx={{ fontSize: '1.5rem', fontWeight: 500, color: theme.palette.grey[800], padding: '2rem 0 1rem 0' }}>Contato</Typography>

      <Grid spacing={3} container>
        <Grid md={6} xs={12} item>
          <Input label='Telefone' mask="phone" name='phone' />
        </Grid>
      </Grid>
    </div>
  )
}