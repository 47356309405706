/* eslint-disable no-unused-vars */
import { transparentize } from 'polished'
import { EDigitalAccountStatus, EInvoiceStatus, EProposalStatus } from '~/graphql/types'

type StatusColor = {
  background: string,
  color: string
}

const PROPOSAL_STATUS_COLORS: { [x in EProposalStatus]: StatusColor } = {
  formalization: {
    color: '#A16F31',
    background: '#FEFCBF'
  },
  finalized: {
    color: '#367154',
    background: '#C6F6D5'
  },
  documentation: {
    color: '#3591E5',
    background: transparentize('0.75', '#3591E5')
  },
  canceled: {
    color: '#BD3535',
    background: 'rgba(239, 68, 68, 0.1)'
  }
}

export function colorProposalStatus(status?: EProposalStatus) {
  if(!status) return {
    background: 'transparent',
    color: 'transparent'
  }
  return PROPOSAL_STATUS_COLORS[status]
}

const CUSTOMER_STATUS_COLORS: { [x in string]: StatusColor } = {
  pending: {
    color: '#A16F31',
    background: '#FEFCBF'
  },
  completed: {
    color: '#367154',
    background: '#C6F6D5'
  },
  rejected: {
    color: '#BD3535',
    background: 'rgba(239, 68, 68, 0.1)'
  }
}

const INVOICE_STATUS_COLORS: { [x in EInvoiceStatus]: StatusColor } = {
  pending: {
    color: '#A16F31',
    background: '#FEFCBF'
  },
  requested: {
    color: '#A16F31',
    background: '#FEFCBF'
  },
  paid: {
    color: '#367154',
    background: '#C6F6D5'
  },
  late: {
    color: '#BD3535',
    background: 'rgba(239, 68, 68, 0.1)'
  },
  canceled: {
    color: '#999999',
    background: '#E6E6E6'
  },
  expired: {
    color: '#999999',
    background: '#E6E6E6'
  },
  paymentRequest: {
    color: '#A16F31',
    background: '#FEFCBF'
  },
  createPayment: {
    color: '#999999',
    background: '#E6E6E6'
  },
  generatePDF: {
    color: '#999999',
    background: '#E6E6E6'
  }
}

export const colorCustomerStatus = (status?: string) => {
  if(!status) return {
    background: 'transparent',
    color: 'transparent'
  }
  return CUSTOMER_STATUS_COLORS[status]
}

export const colorInvoiceStatus = (status?: EInvoiceStatus) => {
  if(!status) return {
    background: 'transparent',
    color: 'transparent'
  }
  return INVOICE_STATUS_COLORS[status]
}

const DIGITAL_ACCOUNT_STATUS: { [x in EDigitalAccountStatus]: StatusColor } = {
  approved: {
    color: '#367154',
    background: '#C6F6D5'
  },
  inAnalysis: {
    color: '#3591E5',
    background: transparentize('0.75', '#3591E5')
  },
  disapproved: {
    color: '#BD3535',
    background: 'rgba(239, 68, 68, 0.1)'
  },
  // não são disponiveis pro usuário
  blocked: {
    color: '#999999',
    background: '#E6E6E6'
  },
  canceled: {
    color: '#999999',
    background: '#E6E6E6'
  },
  inactive: {
    color: '#999999',
    background: '#E6E6E6'
  },
  pending: {
    color: '#999999',
    background: '#E6E6E6'
  }
}

export function colorDigitalAccountStatus(status?: EDigitalAccountStatus) {
  if(!status) return {
    background: 'transparent',
    color: 'transparent'
  }
  return DIGITAL_ACCOUNT_STATUS[status]
}
