import { theme } from "~/utils";
import { ESelfieStatus, SelfieInformationsByStatusProps } from "./types";
import { DigitalAccountPendecyDto } from "~/graphql/types";
import { transparentize } from "polished";

export const selfieInformationsByStatus = (status: ESelfieStatus): SelfieInformationsByStatusProps => {
  if(status === ESelfieStatus.dropped) {
    return {
      cursor: 'pointer',
      disabled: false,
      showPickNewSelfie: true,
      statusInformations: {
        isVisible: true,
        color: '#999999',
        text: 'Capturado'
      },
      color: {
        main: '#999999',
        background: '#FAFAFA',
        label: theme.palette.grey[400],
        description: theme.palette.grey[400],
      }
    }
  }


  if(status === ESelfieStatus.error) {
    return {
      cursor: 'pointer',
      disabled: false,
      showPickNewSelfie: true,
      statusInformations: {
        isVisible: true,
        color: theme.palette.error.main,
        text: 'Negado'
      },
      color: {
        main: theme.palette.error.main,
        background: transparentize('0.9', theme.palette.error.main),
        label: theme.palette.grey[400],
        description: theme.palette.grey[400],
      }
    }
  }

  if(status === ESelfieStatus.success) {
    return {
      cursor: 'default',
      disabled: true,
      showPickNewSelfie: false,
      statusInformations: {
        isVisible: true,
        color: theme.palette.success.main,
        text: 'Aprovado'
      },
      color: {
        main: theme.palette.success.main,
        background: transparentize('0.95', theme.palette.success.main),
        label: theme.palette.grey[400],
        description: theme.palette.grey[400],
      }
    }
  }

  if(status === ESelfieStatus.inAnalysys) {
    return {
      cursor: 'default',
      disabled: true,
      showPickNewSelfie: false,
      statusInformations: {
        isVisible: true,
        color: theme.palette.info.dark,
        text: 'Em análise'
      },
      color: {
        main: theme.palette.success.main,
        background: transparentize('0.95', theme.palette.success.main),
        label: theme.palette.grey[400],
        description: theme.palette.grey[400],
      }
    }
  }

  return {
    cursor: 'pointer',
    disabled: false,
    showPickNewSelfie: true,
    color: {
      main: theme.palette.info.dark,
      background: transparentize('0.95', theme.palette.info.dark),
      label: theme.palette.grey[400],
      description: theme.palette.grey[400],
    }
  }
}

export const getSelfieStatusByIuguWallet = (pendencies?: DigitalAccountPendecyDto): ESelfieStatus => {
  if(!pendencies) {
    return ESelfieStatus.default
  }

  if(pendencies.inAnalysisAt) {
    return ESelfieStatus.inAnalysys
  }

  if(pendencies.approvedAt) {
    return ESelfieStatus.success
  }

  if(pendencies.rejectedAt) {
    return ESelfieStatus.error
  }

  return ESelfieStatus.default
}